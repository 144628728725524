import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PressDetail from "../../components/pressDetail"

const subTitles = []

const content = () => (
  <div>
    <br />
    <div className="descImage">
      <div className="img">
        <img src="/images/press/47/47_img1.png" style={{ width: "300px", margin: "auto" }} alt="CI" />
      </div>
      <div className="desc">&lt;(좌)스테이지파이브CI (우)인텔리안테크CI&gt;</div>
    </div>
    <p>
      <br />
      <br />
      스테이지파이브(대표 서상원)는 (주)인텔리안테크놀로지스(대표 성상엽 KRX 189300, 이하 인텔리안테크)와 파트너십 관계 강화를
      위해, 인텔리안테크가 지분에 참여했다고 27일 밝혔다.
      <br />
      <br />
      금번 지분 참여를 계기로 인텔리안테크는 스테이지파이브의 지분을 최대 5.52% 확보하게 된다.
      <br />
      <br />
      인텔리안테크는 글로벌 위성통신장비분야 1위 기업으로 특히 차세대 이동통신서비스에 필수적인 저궤도 위성통신장비의 기술적인
      선두 업체이다. 이번 지분 참여를 계기로 스테이지파이브와 함께 ‘리얼 5G 혁신서비스’를 위한 파트너 관계를 통해 통신 시장에서
      협업을 이어갈 예정이다.
      <br />
      <br />
      스테이지파이브 관계자는 “통신시장에서 차세대 경쟁력을 갖추려면 5G뿐 아니라 위성통신 관련 역량도 필요하다”며, “향후
      스테이지파이브가 전개할 통신사업이 저궤도 위성통신과도 결합하여 또 하나의 혁신모델로 성공할 수 있도록 최선을 다할 것”이라고
      밝혔다.
      <br />
      <br />
      인텔리안테크는 혁신적인 위성통신 안테나 시스템 기술을 기반으로 저궤도, 중궤도, 정지궤도 위성용 다양한 유저 터미널과
      게이트웨이 안테나, 평면 패널 안테나 등을 개발하고 공급하는 업체이다. 지난 10월에는 스타링크(Starlink)에 이어 세계 두번째로
      저궤도 위성통신 사업자인 유텔셋 원웹(Eutelsat OneWeb)의 서비스를 위한 평판형 안테나 제품을 공개한 바 있다.
      <br />
      <br />
      한편, 스테이지파이브는 지난 19일 5G(28㎓) 신규사업자 주파수 할당 신청 접수를 마친 바 있다. 이를 위해 앞서 밝힌 재무적
      투자자인 신한투자증권 외 ▲클라우드 및 데이터센터 ▲통신장비 및 솔루션 ▲통신디바이스 ▲위성인터넷 ▲엔터테인먼트 ▲모빌리티
      서비스 ▲기업솔루션(B2B) 등 각 분야별 업계를 선도하는 다양한 참여사들로 컨소시엄을 구성하였다.
      <br />
      <br />
      또한, 탄탄한 재무와 안정적인 차세대 통신의 사업성을 담보하며 시장 진출을 준비 중에 있다. 상장신청을 위해 2022년
      일반기업회계기준에서 한층 엄격한 한국채택국제회계기준으로 변경하였으며, 23년까지 통신 인프라와 기술개발에 투자하는 마무리
      단계를 밟고 있다. 현재 기준 운영 효율화를 위한 재정비도 마쳤다. 이를 통해 차기년도 영업익을 100% 개선하며 흑자로의
      턴어라운드 가능성을 시사한 바 있다.
      <br />
      <br />
      스테이지파이브는 기존 밝힌 바와 같이, 재무적 투자자인 신한투자증권을 중심으로 대규모 투자 유치를 이어 나갈 예정이다.
      <br />
    </p>
  </div>
)

const press47 = () => (
  <Layout>
    <SEO title="Press" />
    <PressDetail
      dep="(주)스테이지파이브"
      writer="커뮤니케이션실 pr@stagefive.com"
      date="2023년 12월 27일 즉시배포 가능"
      pageInfo=""
      title="스테이지파이브, 인텔리안테크의 지분 참여…”리얼 5G 혁신서비스 위한 협업 이어갈 것”"
      subTitles={subTitles}
      content={content}
    />
  </Layout>
)

export default press47
